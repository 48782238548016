import React, { Component } from "react"
import { Typography, Dialog, IconButton } from "@material-ui/core"
import { withStyles } from "@material-ui/core/styles"
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined"
import CloseIcon from "@material-ui/icons/Close"

import showdown from "showdown"
const converter = new showdown.Converter()

const style = {
  root: {
    padding: "32px 72px 72px",
    textAlign: "center",
    position: "relative",
  },
  title: {
    fontWeight: "500",
    fontSize: "34px",
    lineHeight: "40px",
    marginBottom: 8,
  },
  textHeader: {
    marginTop: "24px",
    marginBottom: "16px",
    display: "flex",
    alignItems: "center",
    padding: "5px 10px",
    backgroundColor: "#A4E7DB",
    borderRadius: 10,
    "& > svg": {
      marginRight: 8,
    },
  },
  textHeaderContainer: {
    display: "flex",
    justifyContent: "center",
  },
  text: {
    fontFamily: "FaroWeb-RegularLucky",
    fontSize: "15px",
    lineHeight: "140%",
    color: "#2B3B5C",
  },
  closeButton: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  "@media (max-width: 768px)": {
    root: {
      padding: "24px 24px 48px",
    },
  },
}

export function ZeroRiskPackageDialog(props) {
  const { classes, onClose, tooltipContent } = props
  return (
    <div className={classes.root}>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <svg
        width="120"
        height="120"
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <ellipse
          cx="59.143"
          cy="64.2857"
          rx="19.7143"
          ry="19.7143"
          fill="#A4E7DB"
        />
        <path
          d="M48 65.6062L56.2334 73.7143L77.1429 53.1428"
          stroke="#073331"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M59.1424 28.4779L91.571 38.7918V66.6484C91.571 79.094 83.7935 90.3146 71.6516 95.133L59.1424 28.4779ZM59.1424 28.4779L26.7139 38.7918V66.6484C26.7139 79.094 34.4914 90.3146 46.6332 95.133L59.1424 28.4779ZM71.6498 95.1337L59.1424 100.068L46.635 95.1337H71.6498Z"
          stroke="#073331"
          stroke-width="2"
        />
        <path
          d="M60 13.2453V1.71436"
          stroke="#FFD747"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M91.8516 26.4353L100.003 18.2837"
          stroke="#A4E7DB"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M20 18.2837L28.1516 26.4353"
          stroke="#A4E7DB"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M105.04 58.2792H116.571"
          stroke="#F7BDBD"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M3.42871 58.2792H14.9597"
          stroke="#F7BDBD"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
      </svg>

      <Typography variant="h3" color="secondary" className={classes.title}>
        Une solution pour 100% des pannes
      </Typography>
      <Typography>Personne ne restera sur le carreau</Typography>
      <div className={classes.textHeaderContainer}>
        <Typography variant="body2" className={classes.textHeader}>
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.167 3.99996H13.3503C13.442 3.74163 13.5003 3.45829 13.5003 3.16663C13.5003 1.78329 12.3837 0.666626 11.0003 0.666626C10.1253 0.666626 9.36699 1.11663 8.91699 1.79163L8.50033 2.34996L8.08366 1.78329C7.63366 1.11663 6.87533 0.666626 6.00033 0.666626C4.61699 0.666626 3.50033 1.78329 3.50033 3.16663C3.50033 3.45829 3.55866 3.74163 3.65033 3.99996H1.83366C0.908659 3.99996 0.175326 4.74163 0.175326 5.66663L0.166992 14.8333C0.166992 15.7583 0.908659 16.5 1.83366 16.5H15.167C16.092 16.5 16.8337 15.7583 16.8337 14.8333V5.66663C16.8337 4.74163 16.092 3.99996 15.167 3.99996ZM11.0003 2.33329C11.4587 2.33329 11.8337 2.70829 11.8337 3.16663C11.8337 3.62496 11.4587 3.99996 11.0003 3.99996C10.542 3.99996 10.167 3.62496 10.167 3.16663C10.167 2.70829 10.542 2.33329 11.0003 2.33329ZM6.83366 3.16663C6.83366 2.70829 6.45866 2.33329 6.00033 2.33329C5.54199 2.33329 5.16699 2.70829 5.16699 3.16663C5.16699 3.62496 5.54199 3.99996 6.00033 3.99996C6.45866 3.99996 6.83366 3.62496 6.83366 3.16663ZM15.167 13.1666V14.8333H1.83366V13.1666H15.167ZM1.83366 5.66663V10.6666H15.167V5.66663H10.9337L12.667 8.02496L11.317 8.99996L8.50033 5.16663L5.68366 8.99996L4.33366 8.02496L6.06699 5.66663H1.83366Z"
              fill="#073331"
            />
          </svg>
          Forfait transformé en bon d’achat
        </Typography>
      </div>
      <Typography
        variant="body2"
        className={classes.text}
        dangerouslySetInnerHTML={{ __html: converter.makeHtml(tooltipContent) }}
      />
    </div>
  )
}

const StyledZeroRiskPackageDialog = withStyles(style)(ZeroRiskPackageDialog)

export default class ZeroRiskPackageTooltip extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isTooltipDialogOpen: false,
      indexes: [],
    }
  }

  componentDidMount() {
    this.setState({ windowWidth: window.innerWidth })
    window.addEventListener("resize", this.update)
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.update)
  }

  update = () => {
    this.setState({
      windowWidth: window.innerWidth,
    })
  }
  toggleTooltipDialog = () => {
    this.setState(prevState => ({
      isTooltipDialogOpen: !prevState.isTooltipDialogOpen,
    }))
  }

  render() {
    return [
      <InfoOutlinedIcon onClick={this.toggleTooltipDialog} />,
      <Dialog
        open={this.state.isTooltipDialogOpen}
        onClose={this.toggleTooltipDialog}
      >
        <StyledZeroRiskPackageDialog
          tooltipContent={this.props.TooltipContent || ""}
          onClose={this.toggleTooltipDialog}
        />
      </Dialog>,
    ]
  }
}
